import React, { useEffect } from 'react'
import Intercom from '@intercom/messenger-js-sdk'

function Messenger() {
  useEffect(() => {
    Intercom({
      app_id: 'jtt2blq5',
    })

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown')
      }
    }
  }, [])

  return null
}

export default Messenger
