const initialState = {
  areaWithoutCount: 0,
  count: 0,
  priceRange: {},
  whichPPriceRange: {},
  optionsPriceMap: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_PRICE_MAP': {
      return {
        ...state,
        optionsPriceMap: action.payload.optionsPriceMap,
      }
    }
    default:
      return state
  }
}

export default reducer
