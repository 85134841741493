const initialState = {
  cart: {
    id: '',
    products: [],
    allowManualPayment: true,
  },
  cartLength: 0,
  // isCartLengthLoading: false,
  shippingOptions: [],
  userOrders: {},
  userQuotations: {},
  memberInfo: {},
  pdfDetail: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CART_LENGTH': {
      return {
        ...state,
        cartLength: action.payload.cartLength,
      }
    }
    case 'SET_CART': {
      return {
        ...state,
        cart: {
          ...state.cart,
          ...action.payload,
        },
      }
    }
    case 'DELETE_CART_PRODUCT': {
      const productUuid = action.payload.productUuid
      const newProducts = state.cart.products.filter((product) => product.id !== productUuid)
      return {
        ...state,
        cart: {
          ...state.cart,
          products: newProducts,
        },
      }
    }
    case 'SUBTRACT_CART_LENGTH': {
      return {
        ...state,
        cartLength: state.cartLength - 1,
      }
    }
    case 'SET_CART_SHIPPING_OPTIONS': {
      console.log('action.payload.products is: ', action.payload.data)
      return {
        ...state,
        shippingOptions: action.payload.data,
      }
    }
    case 'SET_USER_ORDERS': {
      console.log('action.payload.orders is: ', action.payload.data)
      return {
        ...state,
        userOrders: action.payload.data,
      }
    }
    case 'SET_USER_QUOTATIONS': {
      console.log('action.payload.quotations is: ', action.payload.data)
      return {
        ...state,
        userQuotations: action.payload.data,
      }
    }
    case 'SET_ORDER_INFO': {
      console.log('action.payload.order is: ', action.payload)
      return {
        ...state,
        orderInfo: {
          ...state.orderInfo,
          ...action.payload,
        },
      }
    }
    case 'SET_PRODUCT_COMMENTS': {
      console.log('action.payload.productComments is: ', action.payload)
      return {
        ...state,
        productComments: {
          ...state.productComments,
          ...action.payload,
        },
      }
    }
    case 'SET_PDF_DETAIL': {
      console.log('action.payload.pdfDetail is: ', action.payload)
      return {
        ...state,
        pdfDetail: {
          ...state.pdfDetail,
          ...action.payload,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
