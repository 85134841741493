import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './redux/reducers'

const preloadedState = {}

/*
 * Check with Redux devTools
 * */
// if in client && non in production ?  open Redux devTool
/* eslint-disable no-underscore-dangle */
const enhancers = compose(
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f,
)

// const createStoreWithMiddleware = applyMiddleware()(createStore)
// const initStore = () => {
//   const store = createStoreWithMiddleware(
//     rootReducer,
//     preloadedState,
//     // enhancers,
//   )
//   return store
// }

const store = createStore(rootReducer, preloadedState, enhancers)
// export default initStore

// const sagaMiddleware = createSagaMiddleware()

// const store = createStore(
//     rootReducer,
//     applyMiddleware(sagaMiddleware)
// )

// sagaMiddleware.run(rootSaga)

export default store
