import Router from 'next/router'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useState, useEffect } from 'react'

export default function Loader() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const start = () => {
      console.log('start')
      setLoading(true)
    }
    const end = () => {
      console.log('finished')
      setLoading(false)
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])

  return (
    <AnimatePresence>
      {loading && (
        <Wrapper exit={{ opacity: 0 }} transition={{ duration: 0.5, delay: 1 }}>
          <img src="/images/loader.svg" />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

const Wrapper = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
    transform: translate(0px, -20px);
  }
  background-color: rgba(255, 255, 255, 1);
  z-index: 10000000;
`
