import { combineReducers } from 'redux'
import member from './memberReducer'
import product from './productReducer'
import order from './orderReducer'

export default combineReducers({
  member,
  product,
  order,
})
